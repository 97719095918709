:root{
  --color-text: #FFFFFF;
  --color-text--inverted: #fca120;

  --color-background: #fca120;
  --color-background--inverted: #FFFFFF;
  --color-border: #ff9500;

  --color-background-input: #ffd292;
  --color-input-placeholder: #FFB669

}
