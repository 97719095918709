.b-input{
  background-color: var(--color-background-input);
  color: var(--color-text);
  font-size: 24px;
  padding: 24px 40px;
  border: 4px solid var(--color-border);
  font-family: var(--font-family-primary);
  border-radius: 12px;
  width: 100%;
  display: block;
  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--color-input-placeholder);
    opacity: 1; /* Firefox */
  }

  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--color-input-placeholder);
  }

  &::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--color-input-placeholder);
  }
}
