.b-btn{
  background-color: var(--color-background--inverted);
  color: var(--color-text--inverted);
  font-size: 24px;
  padding: 24px 40px;
  border: 4px solid var(--color-border);
  font-family: var(--font-family-primary);
  border-radius: 12px;
  cursor: pointer;
  &--min{
    min-width: 248px;
  }
  &--boxed{
    border-radius: 0 6px 6px 0;
    border: 0;
    background-color: transparent;
    color: var(--color-text);
  }
}
