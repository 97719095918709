.b-box-icon {
  position: relative;

  &__icon {
    position: absolute;
    top: 50%;
    right: 4px;
    transform: translateY(-50%);
  }
  &__input{
    padding-right: 80px;
  }
}
