@import "~bootstrap/scss/mixins/breakpoints";

.b-text {
  font-family: var(--font-family-primary);
  color: var(--color-text);
  font-size: 16px;

  &--xl {
    font-size: 64px;
    margin: 0;
  }

  &--5xl {
    font-size: 148px;
    margin: 0;

  }

  &--logo {
    font-size: 36px;

    @include media-breakpoint-up(lg) {
      font-size: 72px;

    }
  }
}

