.c-values-dealer {

  &__btn {
    position: fixed;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__content {
    position: fixed;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }

  &__value-content {
    &--active {
      animation: rotate-scale-up 0.65s linear both;
    }
    &--question{
      animation: pulsate-fwd 2s ease-in-out infinite both;
    }
  }

  &__value {
    opacity: 0;
    transition: opacity .25s ease-in-out;
    position: absolute;
    left: 50%;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -50%);

    &--active {
      opacity: 1;

    }
  }
}

@keyframes rotate-scale-up {
  0% {
    transform: scale(1) rotateZ(0)
  }
  50% {
    transform: scale(4) rotateZ(180deg)
  }
  100% {
    transform: scale(1) rotateZ(360deg)
  }
}
@keyframes pulsate-fwd {
  0% {
    transform: scale(1) rotate(-20deg) ;
  }
  50% {
    transform: scale(1.5) rotate(20deg) ;
  }
  100% {
    transform: scale(1) rotate(-20deg) ;
  }
}
