body{
  background-color: var(--color-background);
  font-family: var(--font-family-primary);
  color: var(--color-text);
  font-size: 24px;
}

*{
  box-sizing: border-box;
}
