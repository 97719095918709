
// ******************************************************************************
//  # Jost
// ******************************************************************************

@font-face {
  font-family: "Nickname";
  src: url("../../assets/fonts/Nickname.otf");
  font-weight: 400;
}
//
//@font-face {
//  font-family: "Jost";
//  src: url("../../assets/fonts/Jost-ExtraBold.ttf");
//  font-weight: 800;
//}
//@font-face {
//  font-family: "Jost";
//  src: url("../../assets/fonts/Jost-Bold.ttf");
//  font-weight: 700;
//}
//@font-face {
//  font-family: "Jost";
//  src: url("../../assets/fonts/Jost-SemiBold.ttf");
//  font-weight: 600;
//}
//@font-face {
//  font-family: "Jost";
//  src: url("../../assets/fonts/Jost-Medium.ttf");
//  font-weight: 500;
//}
//@font-face {
//  font-family: "Jost";
//  src: url("../../assets/fonts/Jost-Regular.ttf");
//  font-weight: 400;
//}
//
//@font-face {
//  font-family: "Jost";
//  src: url("../../assets/fonts/Jost-Thin.ttf");
//  font-weight: 300;
//}
